
.PhoneInput {
  border: 1px solid #ff7c39;
  padding-left: 12px;
  border-radius: 16px;
  background:#ffffffad;
}

.PhoneInputInput {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  border: none;
  background-color: transparent;
  width: 100%;
  caret-color: #000;
  transform: translateY(0.000000001%);
  z-index: 2;
  border-radius: 0px 20px 20px 0px;
  padding: 24px 30px;
  transition: 0.2s linear;
}

.PhoneInputInput:hover {
  border-color: rgba(22, 26, 32, 0.3);
}

.PhoneInputInput:focus {
  outline: none;
  border-color: rgb(255 124 57);
}

.PhoneInputCountrySelect > option {
  font-size: 16px;
}
