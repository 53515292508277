.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: #161a20;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-position: center;
}

.icon-xs {
  width: 18px;
  height: 18px;
}

.icon-sm {
  width: 20px;
  height: 20px;
}

.icon-lg {
  width: 30px;
  height: 30px;
}

.icon-search {
  mask-image: url("../icons/search.svg");
  -webkit-mask-image: url("../icons/search.svg");
}

.icon-check {
  mask-image: url("../icons/check.svg");
  -webkit-mask-image: url("../icons/check.svg");
}

.icon-arrow-narrow-right {
  mask-image: url("../icons/arrow-narrow-right.svg");
  -webkit-mask-image: url("../icons/arrow-narrow-right.svg");
}
